// Themes
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
};

export const PRODUCT_OVER_STOCKED_STEPS = [
  {
    color: "rgba(35, 48, 67, 1)",
    title: "Increase PPC",
    link: "https://advertising.amazon.com/cm/campaigns",
  },
  {
    color: "rgba(35, 48, 67, 0.9)",
    title: "Run a deal",
    link: "https://sellercentral.amazon.com/merchandising-new/",
  },
  {
    color: "rgba(35, 48, 67, 0.8)",
    title: "Lower price",
    link: "https://sellercentral.amazon.com/inventory?viewId=PRICING",
  },
  {
    color: "rgba(35, 48, 67, 0.7)",
    title: "Move to Amazon Outlet",
    link: "https://sellercentral.amazon.com/inventoryplanning/manageinventoryhealth?ref=fba_dashboard_optimize_inventory&sort_column=est_overstock&sort_direction=desc&RECOMMENDATION=OUTLET_DEAL",
  },
  {
    color: "rgba(35, 48, 67, 0.6)",
    title: "Liquidation",
    link: "https://sellercentral.amazon.com/recoveryui/removal-order/create",
  },
  {
    color: "rgba(35, 48, 67, 0.5)",
    title: "Disposal",
    link: "https://sellercentral.amazon.com/recoveryui/removal-order/create",
  },
];
