import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import RDP from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";

import { addAdvanceBusinessReportEvent } from "../../../../../../redux/slices/advance_business_report";
import { storeAnalyticsSelector } from "../../../../../../redux/slices/store_analytics";
import * as S from "../../shared/DatePicker/styles";

const AddEventForm = (props) => {
  const dispatch = useDispatch();
  const { store } = useSelector(storeAnalyticsSelector);
  const [date, setDate] = useState();
  const [eventDescription, setEventDescription] = useState("");
  const [pickerDisplay, setPickerDisplay] = useState("none");
  const [isLoading, setIsLoading] = useState(false);

  const handleSelectorClick = () => {
    setPickerDisplay(pickerDisplay === "none" ? "flex" : "none");
  };

  const parseEventDate = (date) => {
    const utcCalculatedDate = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );
    const dateString = utcCalculatedDate.toISOString().slice(0, 10);
    return dateString;
  };

  const handleAddEvent = () => {
    setIsLoading(true);
    const dateString = parseEventDate(date);
    dispatch(
      addAdvanceBusinessReportEvent({
        store: store,
        event_date: dateString,
        event_description: eventDescription,
        parent_asin: props.level1 === "Parent ASIN" ? props.level2 : "",
        sku: props.level1 === "SKU" ? props.level2 : "",
        child_asin: props.level1 === "Child ASIN" ? props.level2 : "",
      })
    );
  };

  return (
    <div>
      <table>
        <tbody>
          <tr>
            <td>
              <TextField
                label="Event Description"
                variant="outlined"
                size="small"
                value={eventDescription}
                onChange={(e) => setEventDescription(e.target.value)}
              />
            </td>
            <td>
              <S.Root>
                <S.SelectorContainer onClick={handleSelectorClick}>
                  <S.StyledCalendarTodayIcon />
                  <S.SelectorText style={{ color: "darkgray" }}>
                    {date ? date.toLocaleDateString() : "Date"}
                  </S.SelectorText>
                </S.SelectorContainer>
                <S.PickerElements
                  display={pickerDisplay}
                  style={{
                    width: "200%",
                    right: "-50%",
                    top: "150%",
                    border: 0,
                  }}
                >
                  <S.PickerContainer>
                    <S.CalendarContainer>
                      <RDP
                        selected={date}
                        onChange={(newValue) => setDate(newValue)}
                        inline
                      />
                    </S.CalendarContainer>
                  </S.PickerContainer>
                </S.PickerElements>
              </S.Root>
            </td>
            <td>
              {props.level1 === "SKU" ? (
                <TextField
                  label="SKU"
                  variant="outlined"
                  size="small"
                  value={props.level2}
                  disabled
                />
              ) : null}
            </td>
            <td>
              {props.level1 === "Parent ASIN" ? (
                <TextField
                  label="Parent ASIN"
                  variant="outlined"
                  size="small"
                  value={props.level2}
                  disabled
                />
              ) : null}
            </td>
            <td>
              {props.level1 === "Child ASIN" ? (
                <TextField
                  label="Child ASIN"
                  variant="outlined"
                  size="small"
                  value={props.level2}
                  disabled
                />
              ) : null}
            </td>
          </tr>
        </tbody>
      </table>
      <Button
        onClick={handleAddEvent}
        variant="contained"
        disabled={!eventDescription || !date || isLoading}
        style={{ padding: "0px 7px" }}
      >
        {isLoading ? "Loading..." : "Add"}
      </Button>
    </div>
  );
};

export default AddEventForm;
