import { Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";

const Wrapper = styled.div``;

const Container = styled.div`
  display: flex;
  gap: 127px;
`;

const StepContainer = styled.div`
  position: relative;
  height: 50px;
  width: fit-content;
`;

const StepStart = styled.div`
  height: 0px;
  width: 0px;
  position: absolute;
  border-top: 25px solid transparent;
  border-left: 25px solid #f7f9fc;
  border-right: 25px solid transparent;
  border-bottom: 25px solid transparent;
  z-index: 1;
`;

const StepContent = styled.div`
  display: flex;
  position: absolute;
`;

const StepMiddle = styled.div(
  (props) => `
  background: ${props.color};
  height: 50px;
  width: 120px;
  top: 0;
  display: flex;
  align-items: center;
`
);

const StepEnd = styled.div(
  (props) => `
  height: 0px;
  width: 0px;
  border-top: 25px solid transparent;
  border-left: 25px solid ${props.color};
  border-right: 25px solid transparent;
  border-bottom: 25px solid transparent;
  z-index: 2;
`
);

const textStyle = {
  textAlign: "center",
  color: "white",
};

const titleStyle = {
  paddingTop: "20px",
  paddingBottom: "5px",
  fontSize: "15px",
  fontWeight: "400",
  textAlign: "center",
};

function Steps(props) {
  return (
    <Wrapper>
      <Typography sx={titleStyle}>{props.title}</Typography>
      <Container
        style={{
          marginTop: "10px",
          marginLeft: "-140px",
          justifyContent: "center",
        }}
      >
        {props.steps.map((step) => (
          <StepContainer>
            <StepStart />
            <StepContent>
              <StepMiddle color={step.color}>
                <a
                  href={step.link}
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <Typography
                    sx={{
                      ...textStyle,
                      ...{ fontSize: step.title.length > 16 ? "12px" : "14px" },
                      ...{
                        paddingLeft: step.title.length > 16 ? "30px" : "40px",
                      },
                    }}
                  >
                    {step.title}
                  </Typography>
                </a>
              </StepMiddle>
              <StepEnd color={step.color} />
            </StepContent>
          </StepContainer>
        ))}
      </Container>
    </Wrapper>
  );
}

export default Steps;
//rgba(135, 206, 235, 1)
