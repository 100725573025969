import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  errorsList: [],
};

const errorsSlice = createSlice({
  name: "errors",
  initialState,
  reducers: {
    addError: (state, action) => {
      state.errorsList.push(action.payload);
    },
    removeError: (state, action) => {
      state.errorsList = state.errorsList.filter(
        (error) => error.url !== action.payload
      );
    },
  },
});

export const displayErrorWithTimeout = (error) => (dispatch) => {
  dispatch(addError(error));
  setTimeout(() => {
    dispatch(removeError(error.url));
  }, 4000);
};

const { addError, removeError } = errorsSlice.actions;

// A selector
export const errorsSelector = (state) => state.errors.errorsList;

// The reducer
export default errorsSlice.reducer;
