// import { Typography } from "@mui/material";
import React from "react";
// import { useDispatch, useSelector } from "react-redux";
// import styled from "styled-components";

import TableCustom from "./AllProductsTable";

const StoreProductsAnalysis = ({ value }) => {
  let params = {};
  params["periodicity"] = "day";
  params["store"] = value;
  let current = {};
  let nowDate = new Date();
  current["end_date"] = nowDate.toISOString().split("T")[0];
  nowDate.setDate(nowDate.getDate() - 30);
  current["start_date"] = nowDate.toISOString().split("T")[0];
  params["compareConditions"] = { current: current };
  return (
    <React.Fragment>
      <>
        <TableCustom value={params} />
      </>
    </React.Fragment>
  );
};

export default StoreProductsAnalysis;
