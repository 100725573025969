import { InputLabel } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  advanceBusinessReportSelector,
  removeAdvanceBusinessReportEvent,
} from "../../../../../../redux/slices/advance_business_report";

const RemoveEventForm = () => {
  const dispatch = useDispatch();
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { advanceBusinessReportEvents } = useSelector(
    advanceBusinessReportSelector
  );

  useEffect(() => {
    setEvents(advanceBusinessReportEvents);
  }, [advanceBusinessReportEvents]);

  const handleRemoveEvent = () => {
    setIsLoading(true);
    dispatch(removeAdvanceBusinessReportEvent({ event_id: selectedEvent.id }));
  };

  const handleListItemClick = (event) => {
    setSelectedEvent(event);
  };

  return (
    <Box sx={{ width: 350 }}>
      <FormControl fullWidth size="small" sx={{ width: 350, marginTop: "7px" }}>
        <InputLabel>
          {events.length === 0 ? "No Events" : "Choose Event To Remove"}
        </InputLabel>
        <Select
          value={selectedEvent?.event}
          label={events.length === 0 ? "No Events" : "Choose Event To Remove"}
          disabled={events.length === 0}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
        >
          {events.map((event, index) => (
            <MenuItem
              selected={selectedEvent === event}
              key={index}
              value={event.event_description}
              onClick={() => handleListItemClick(event)}
            >
              {event.event_description} - ({event.event_date})
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        onClick={handleRemoveEvent}
        variant="contained"
        disabled={selectedEvent === null || isLoading}
        color="error"
        style={{ padding: "0px 7px", marginTop: "7px" }}
      >
        {isLoading ? "Loading..." : "Remove"}
      </Button>
    </Box>
  );
};

export default RemoveEventForm;
