import "@fontsource/inter";
import "@fontsource/inter/600.css";
import InfoIcon from "@mui/icons-material/Info";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import PropTypes from "prop-types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { SiSlack } from "react-icons/si";
import styled from "styled-components";

import { UnitEconomics } from "./UnitEconomics";

function slackChannelPerBrand(store) {
  switch (store) {
    case "volko":
      return "C06ER403154";
    case "garnetics":
      return "C06NQ5TGW15";
    case "duramont":
      return "C06J5ETKZV3";
    case "buttertree":
      return "C06U24RSS1X";
    case "trueocity":
      return "C06MAG1UGPR";
    case "atlasonix":
      return "C06J7DA9M7Y";
    case "vos":
      return "C06U24RSS1X";
    case "rushmorerose":
      return "C070EER4S8Z";
    default:
      return "C03DS77LF53";
  }
}

function calculations(products, _compareConditions) {
  let quantity3pl = 0,
    quantityfba = 0,
    quantityfbaAvailable = 0,
    quantityfbaReserved = 0,
    quantityfbaInReceiving = 0,
    quantityfbaInWorkAndShip = 0,
    shipped_eta = [],
    shipped_qty = 0;

  if (products) {
    products.forEach((product) => {
      //3pl_quantity_column_variables
      if (product) {
        if (product["3pl"] && !isNaN(parseInt(product["3pl"].quantity))) {
          quantity3pl += parseInt(product["3pl"].quantity);
        }

        if ("fba_report" in product) {
          //fba_quantity_column_variables
          quantityfba += product.fba_report?.quantity;
          quantityfbaAvailable += product.fba_report?.available;
          quantityfbaReserved += product.fba_report?.reserved;
          quantityfbaInReceiving += product.fba_report?.receiving;
          quantityfbaInWorkAndShip +=
            product.fba_report?.working + product.fba_report?.shipped;

          shipped_qty += product.velocity ? product.velocity.shipped_qty : 0;
          if (product.velocity?.date != null) {
            shipped_eta.push(product.velocity?.date); // "Many"
          }
        }
      }
    });
  }

  return {
    quantity3pl,
    quantityfba,
    quantityfbaAvailable,
    quantityfbaReserved,
    quantityfbaInReceiving,
    quantityfbaInWorkAndShip,
    shipped_eta,
    shipped_qty,
  };
}

const formatRow = (row) => {
  Object.keys(row).forEach((key) => {
    if (key !== "total" && key !== "id") {
      row[key] = formatValue(row, key);
    }
  });
};

const formatValue = (row, key) => {
  if (
    (row.field === "profit" || row.field === "ppc" || row.field === "sales") &&
    key !== "field"
  ) {
    row[key].value = `$${Number(row[key].value).toLocaleString()}`;
    return row[key];
  } else if (row.field === "units" && key !== "field") {
    row[key].value = `${Number(row[key].value).toLocaleString()}`;
    return row[key];
  } else {
    const value = row[key].toLocaleString();
    return key !== "field" ? `$${value}` : value;
  }
};

const getTableDailyData = (salesData, profitabilityData, ranksData, leaf) => {
  let formatedSalesData = tableSalesDataForProduct(
    leaf === "yes" ? [salesData] : Object.values(salesData)
  );

  let formatedProfitData = tableProfitDataForProduct(
    leaf === "yes" ? [profitabilityData] : Object.values(profitabilityData)
  );

  formatedSalesData.push(...formatedProfitData);

  let formatedRanksData = tableRanksDataForProduct(
    leaf === "yes" ? [ranksData] : Object.values(ranksData)
  );

  if (Object.keys(formatedRanksData).length > 1) {
    formatedSalesData.push(...formatedRanksData);
  }
  return formatedSalesData;
};

const emptyProfitBreakdownObject = () => {
  return {
    Sales: 0,
    Promotions: 0,
    Refunds: 0,
    Referals: 0,
    Fulfillments: 0,
    Cogs: 0,
    "PPC Product": 0,
    "PPC Brand": 0,
    "PPC Display": 0,
    "Extra fees": 0,
  };
};

const tableProfitDataForProduct = (productsData) => {
  let profitRow = {
    id: 4,
    total: { value: 0, breakdown: emptyProfitBreakdownObject() },
    field: "profit",
  };
  let marginRow = { id: 5, total: 0, field: "margin" };

  Object.values(productsData).forEach((data) => {
    if (data) {
      Object.entries(data).forEach(([date, value]) => {
        if (!profitRow[date]) {
          profitRow[date] = {
            value: 0,
            breakdown: emptyProfitBreakdownObject(),
          };
          marginRow[date] = 0;
        }
        // Calculate profit for the date
        profitRow[date].value +=
          value.orderedProductSales -
          value.item_promotion_discount -
          value.refunded_amount -
          value.referal_fee -
          value.fulfillment_fee -
          value.cogs_cost -
          value.product_cost -
          value.brand_cost -
          value.display_cost +
          value.extra_fee;
        profitRow[date].value = parseFloat(profitRow[date].value.toFixed(2));

        // calculate profit breakdown for the date
        profitRow[date].breakdown["Sales"] += value.orderedProductSales;
        profitRow.total.breakdown["Sales"] += value.orderedProductSales;
        profitRow[date].breakdown["Promotions"] +=
          value.item_promotion_discount;
        profitRow.total.breakdown["Promotions"] +=
          value.item_promotion_discount;
        profitRow[date].breakdown["Refunds"] += value.refunded_amount;
        profitRow.total.breakdown["Refunds"] += value.refunded_amount;
        profitRow[date].breakdown["Referals"] += value.referal_fee;
        profitRow.total.breakdown["Referals"] += value.referal_fee;
        profitRow[date].breakdown["Fulfillments"] += value.fulfillment_fee;
        profitRow.total.breakdown["Fulfillments"] += value.fulfillment_fee;
        profitRow[date].breakdown["Cogs"] += value.cogs_cost;
        profitRow.total.breakdown["Cogs"] += value.cogs_cost;
        profitRow[date].breakdown["PPC Product"] += value.product_cost;
        profitRow.total.breakdown["PPC Product"] += value.product_cost;
        profitRow[date].breakdown["PPC Brand"] += value.brand_cost;
        profitRow.total.breakdown["PPC Brand"] += value.brand_cost;
        profitRow[date].breakdown["PPC Display"] += value.display_cost;
        profitRow.total.breakdown["PPC Display"] += value.display_cost;
        profitRow[date].breakdown["Extra fees"] += value.extra_fee;
        profitRow.total.breakdown["Extra fees"] += value.extra_fee;
        marginRow[date] += value.orderedProductSales;
      });
    }
  });

  Object.keys(marginRow).forEach((key) => {
    if (typeof marginRow[key] === "number" && key !== "id" && key !== "total") {
      profitRow.total.value += profitRow[key].value;
      marginRow.total += marginRow[key];
      if (marginRow[key] === 0 || profitRow[key].value === 0) {
        marginRow[key] = "0%";
      } else {
        marginRow[key] =
          ((profitRow[key].value / marginRow[key]) * 100).toFixed(2) + "%";
      }
    }
  });

  marginRow.total = `${(
    (profitRow.total.value / marginRow.total) *
    100
  ).toFixed(2)}%`;
  profitRow.total.value = `$${parseFloat(
    profitRow.total.value.toFixed(2)
  ).toLocaleString()}`;
  formatRow(profitRow);
  return [profitRow, marginRow];
};

const formatBreakdownTooltip = (breakdown) => {
  return Object.entries(breakdown)
    .map(([key, value]) => {
      let prefix = "-$";
      if (key === "Sales") {
        prefix = "+$";
      } else if (key.includes("sales")) {
        let precentage =
          (value /
            (breakdown["Organic sales"] + breakdown["Sponsored sales"])) *
          100;
        key = key.replace("sales", "(" + precentage.toFixed(0) + "%)");
        prefix = "$";
      } else if (key.includes("units")) {
        let precentage =
          (value /
            (breakdown["Organic units"] + breakdown["Sponsored units"])) *
          100;
        key = key.replace("units", "(" + precentage.toFixed(0) + "%)");
        prefix = "";
      } else if (key === "Extra fees" || key.includes("SV(1W)")) {
        prefix = "";
      }
      const formattedValue = value % 1 === 0 ? value : value.toFixed(2);
      return `${key}: ${prefix}${Number(formattedValue).toLocaleString()}`;
    })
    .join("\n");
};

const tableColumnsForProduct = (data, leaf) => {
  // Function to determine cell color based on value

  const getCellColorClass = (position) => {
    if (position >= 1 && position <= 5) return "strong-green-cell"; // "rgba(76, 175, 80, 0.5)";
    if (position >= 6 && position <= 10) return "light-green-cell"; // rgba(76, 175, 80, 0.2)";
    if (position >= 11 && position <= 20) return "yellow-cell"; // rgba(255, 235, 59, 0.2)";
    if (position >= 21 && position <= 29) return "orange-cell"; // "rgba(255, 152, 0, 0.2)";
    if (position >= 30) return "red-cell"; //"rgba(244, 67, 54, 0.2)";
    return "transparent";
  };

  let columns = [];
  if (data) {
    columns = [...data.dates].reverse().map((date) => ({
      field: date,
      headerName: date,
      width: 100,
      cellClassName: (params) => {
        if (params.row.field === "rank") {
          return getCellColorClass(params.row[date]);
        }
        return "";
      },
      renderCell: (params) => {
        if (
          (params.row.field === "units" || params.row.field === "sales") &&
          leaf === "yes"
        ) {
          return <span>{params.row[date].value}</span>;
        } else if (
          (params.row.field === "profit" ||
            params.row.field === "ppc" ||
            params.row.field === "units" ||
            params.row.field === "sales") &&
          params.row[date]
        ) {
          return (
            <Tooltip
              title={
                <pre>{formatBreakdownTooltip(params.row[date].breakdown)}</pre>
              }
            >
              <span>{params.row[date].value}</span>
            </Tooltip>
          );
        } else if (params.row.field === "rank") {
          return <span>{params.row[date] === 0 ? "-" : params.row[date]}</span>;
        }
      },
    }));
  }
  columns.unshift({
    field: "total",
    headerName: "Total/Avg",
    width: 100,
    renderCell: (params) => {
      if (
        (params.row.field === "units" || params.row.field === "sales") &&
        leaf === "yes"
      ) {
        return <span>{params.row.total.value}</span>;
      } else if (
        params.row.field === "profit" ||
        params.row.field === "units" ||
        params.row.field === "sales" ||
        params.row.field === "rank"
      ) {
        return (
          <Tooltip
            title={
              <pre>{formatBreakdownTooltip(params.row.total.breakdown)}</pre>
            }
          >
            <span
              style={{
                whiteSpace: "normal",

                fontSize: params.row.field === "rank" ? "11px" : "inherit",
              }}
            >
              {params.row.total.value}
            </span>
          </Tooltip>
        );
      }
    },
  });

  columns.unshift({
    field: "field",
    headerName: "",
    width: 100,
  });

  return columns;
};

const calculateRow = (rows, data, field, index, productsData) => {
  const initBreakdown = {
    value: 0,
    breakdown: { [`Organic ${field}`]: 0, [`Sponsored ${field}`]: 0 },
  };
  let defaultRow = {
    id: index,
    total: field === "units" || field === "sales" ? initBreakdown : 0,
    field,
  };
  let row = rows[index] || defaultRow;

  data.dates.forEach((date, i) => {
    let value;
    let breakdown = {};

    // Determine value and breakdown based on the field
    if (field === "ppc") {
      value = data[field][i];
      breakdown = {
        "PPC Brand": data["ppc_b"][i],
        "PPC Display": data["ppc_d"][i],
        "PPC Product": data["ppc_p"][i],
      };
    } else if (field === "units") {
      value = data[field][i];
      breakdown = {
        "Organic units": value - data.sponsoredUnits[i],
        "Sponsored units": data.sponsoredUnits[i],
      };
    } else if (field === "sales") {
      value = data[field][i];
      breakdown = {
        "Organic sales": value - data.sponsoredSales[i],
        "Sponsored sales": data.sponsoredSales[i],
      };
    } else {
      value =
        field === "avg_price"
          ? calculateAvgPrice(data, i, productsData)
          : data[field][i];
    }

    // Update row for the current date
    if (field === "ppc" || field === "units" || field === "sales") {
      if (row[date]) {
        row[date].value += value;
        Object.entries(breakdown).forEach(([key, val]) => {
          row[date].breakdown[key] += val;
        });
      } else {
        row[date] = { value, breakdown: { ...breakdown } };
      }
      row[date].value = Number(row[date].value.toFixed(2));

      if (field === "units" || field === "sales") {
        row.total.value += value;
        Object.entries(breakdown).forEach(([key, val]) => {
          row.total.breakdown[key] += val;
        });
      } else {
        row.total += value;
      }
    } else {
      row[date] = row[date] ? row[date] + value : value;
      row.total += value;
    }
  });

  return row;
};

const calculateAvgPrice = (data, i, productsData) => {
  let allSales = 0;
  let allUnits = 0;
  productsData = productsData.filter((product) => product["dates"].length > 0);
  productsData.forEach((product) => {
    allSales += product["sales"][i];
    allUnits += product["units"][i];
  });
  let price = allSales / (allUnits === 0 ? 1 : allUnits) / productsData.length;
  price = Number(price.toFixed(2));
  return Object.values(productsData).length > 1 ? price : data["avg_price"][i];
};

const tableRanksDataForProduct = (productsData) => {
  let rows = [];
  let allChildrenTop3 = [];

  //gather terms from all children, top 3 from each child
  productsData.forEach((product) => {
    let top3 = [];
    if (product && Object.keys(product).length > 0) {
      for (const [key, value] of Object.entries(product)) {
        top3.push({ key, value });
        top3.sort((a, b) => b.value.sv_1w - a.value.sv_1w);
        if (top3.length > 3) {
          top3.pop();
        }
      }
    }
    if (top3.length > 0) {
      allChildrenTop3.push(...top3);
    }
  });
  //sort unique terms by sv_1w and take overall top 3
  allChildrenTop3.sort((a, b) => b.value.sv_1w - a.value.sv_1w);
  const uniqueTermsSorted = new Set(allChildrenTop3.map((item) => item.key));
  const top3Terms = Array.from(uniqueTermsSorted).slice(0, 3);

  //for each term in the overall top 3 terms,
  //find the minimum rank for each date in one of the children
  top3Terms.forEach((keyToMatch, index) => {
    const matchingChildValues = allChildrenTop3.filter(
      (item) => item.key === keyToMatch
    );
    let minRankOnTerm = {
      key: keyToMatch,
      value: { sv_1w: matchingChildValues[0].value.sv_1w },
    };
    matchingChildValues.forEach((childValue) => {
      Object.entries(childValue.value).forEach(([keyDate, value]) => {
        if (!minRankOnTerm.value[keyDate]) {
          minRankOnTerm.value[keyDate] = 0;
        }
        if (
          value !== 0 &&
          (minRankOnTerm.value[keyDate] === 0 ||
            value < minRankOnTerm.value[keyDate])
        ) {
          minRankOnTerm.value[keyDate] = value;
        }
      });
    });

    let toolKey = minRankOnTerm.key + " SV(1W)";
    let row = {
      id: index + 6,
      total: {
        value: minRankOnTerm.key,
        breakdown: {
          [toolKey]: minRankOnTerm.value.sv_1w,
        },
      },
      field: "rank",
      ...minRankOnTerm.value,
    };
    rows.push(row);
  });

  return rows;
};

const tableSalesDataForProduct = (productsData) => {
  let rows = [];
  let rowsToExclude = [
    "dates",
    "ppc_b",
    "ppc_d",
    "ppc_p",
    "sponsoredUnits",
    "sponsoredSales",
  ];
  Object.values(productsData).forEach((data) => {
    if (data && data.dates && data.dates.length > 0) {
      rows = Object.keys(data)
        .filter((x) => !rowsToExclude.includes(x))
        .map((field, index) =>
          calculateRow(rows, data, field, index, productsData)
        );
    }
  });
  rows.forEach(formatRow);
  if (rows.length > 0) {
    // setPpcPerUnit((rows[3].total / rows[0].total).toFixed(2)); // substract last days units
    rows[1].total = `$${(rows[2].total.value / rows[0].total.value).toFixed(
      2
    )}`;
    rows[0].total.value = Number(rows[0].total.value).toLocaleString();
    rows[2].total.value = `$${Number(rows[2].total.value).toLocaleString()}`;
    if (rows[3]) {
      rows[3].total = `$${Number(rows[3].total).toLocaleString()}`;
    }
  }
  return rows;
};

const calcPpcPerUnit = (data) => {
  let ppcPerUnit = 0;
  if (data) {
    if ("units" in data) {
      //child
      ppcPerUnit = 0;
      if ("ppc" in data) {
        const last7Ppc = data.ppc.slice(-7);
        const last7Units = data.units.slice(-7);
        ppcPerUnit =
          last7Ppc.reduce((sumPpc, currPpc) => sumPpc + currPpc, 0) /
          last7Units.reduce((sumUnits, currUnits) => sumUnits + currUnits, 0);
      }
    } else {
      //parent
      Object.values(data).forEach((value) => {
        ppcPerUnit += calcPpcPerUnit(value);
      });
      ppcPerUnit = ppcPerUnit / Object.keys(data).length;
    }
  }
  ppcPerUnit =
    isNaN(Number(ppcPerUnit.toFixed(2))) || ppcPerUnit === Infinity
      ? 0
      : Number(ppcPerUnit.toFixed(2));
  return ppcPerUnit;
};

const DataTable = styled(DataGridPro)({
  ".MuiDataGrid-main>div:nth-child(3)": {
    display: "none",
  },
  "& .MuiDataGrid-pinnedColumns": {
    backgroundColor: "white",
  },
  "& .MuiDataGrid-columnsContainer": {
    backgroundColor: "#fafafa",
  },
  "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
    borderRight: "1px solid #f0f0f0",
  },
  "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
    borderBottom: "1px solid #f0f0f0",
  },
  "& .MuiDataGrid-cell": {
    color: "rgba(0,0,0,.85)",
  },
  "& .MuiDataGrid-cellContent": {
    overflow: "visible",
  },
  "& .MuiPaginationItem-root": {
    borderRadius: 0,
  },
  "& .MuiDataGrid-footerContainer": {
    display: "none",
  },
  "& .strong-green-cell": {
    backgroundColor: "rgba(76, 175, 80, 0.5)",
  },
  "& .light-green-cell": {
    backgroundColor: "rgba(76, 175, 80, 0.2)",
  },
  "& .yellow-cell": {
    backgroundColor: "rgba(255, 235, 59, 0.2)",
  },
  "& .orange-cell": {
    backgroundColor: "rgba(255, 152, 0, 0.2)",
  },
  "& .red-cell": {
    backgroundColor: "rgba(244, 67, 54, 0.2)",
  },
});

const ProductRowDetailed = ({
  store,
  sku,
  product,
  compareConditions = {},
  leaf,
  openAllRows,
  inventoryData,
  salesData,
  unitEconomicsData,
  filters,
  setParentShow,
  profitabilityData,
  ranksData,
}) => {
  const charMax = 45;

  const apiRef = useGridApiRef();

  ProductRowDetailed.propTypes = {
    sku: PropTypes.string,
    store: PropTypes.string,
    product: PropTypes.object,
    compareConditions: PropTypes.object,
    leaf: PropTypes.string,
    openAllRows: PropTypes.bool,
    inventoryData: PropTypes.object,
    salesData: PropTypes.object,
    filters: PropTypes.array,
    setParentShow: PropTypes.func,
  };

  const {
    quantity3pl,
    quantityfbaAvailable,
    quantityfbaReserved,
    quantityfbaInReceiving,
    quantityfbaInWorkAndShip,
    shipped_qty,
    shipped_eta,
  } = calculations(inventoryData, compareConditions);

  const velocityDays = 7;
  const showVelocityChange = (data) => {
    const velocity = calcVelocity(data);
    const velocityChange = calcVelocityChange(data);
    const vlChangeStr =
      velocityChange === Infinity || isNaN(velocityChange)
        ? 0
        : velocityChange + "%";
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div>{velocity.toFixed(2)}</div>
        <div style={{ fontSize: 12, marginLeft: 2 }}>
          {"(" + vlChangeStr + ")"}
        </div>
      </div>
    );
  };

  const calcVelocity = useCallback((data, isPrevWeek = false) => {
    let velocity = 0;
    const velocityDays = 7; // assuming velocityDays is defined somewhere
    let velDays = velocityDays + 1; // ignore today
    let startIdx = isPrevWeek ? -(2 * velDays) : -velDays;
    let endIdx = isPrevWeek ? -velDays : -1;

    if (data) {
      if ("units" in data) {
        // child
        velocity =
          data.units.slice(startIdx, endIdx).reduce((a, b) => a + b, 0) /
          (data.units.slice(startIdx, endIdx).length || 1);
      } else {
        // parent
        Object.values(data).forEach((value) => {
          velocity += calcVelocity(value, isPrevWeek);
        });
      }
    }
    return velocity;
  }, []);

  const calcVelocityChange = useCallback(
    (data) => {
      const velocity = calcVelocity(data);
      const prevVelocity = calcVelocity(data, true);
      const velocityChange =
        ((velocity - prevVelocity) / (prevVelocity === 0 ? 1 : prevVelocity)) *
        100;
      return velocityChange.toFixed(2);
    },
    [calcVelocity]
  );

  const isAnyDateLessThan30Days = (shipped_eta) => {
    const currentDate = new Date();
    return shipped_eta.some((dateString) => {
      const date = new Date(dateString);
      const diffTime = Math.abs(date - currentDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays <= 30;
    });
  };

  const calcRealisticStock = useCallback(() => {
    const isOnTheWayRelevant = isAnyDateLessThan30Days(shipped_eta);
    const realisticStock =
      quantityfbaAvailable +
      quantityfbaReserved +
      quantityfbaInReceiving +
      quantity3pl +
      (isOnTheWayRelevant ? shipped_qty : 0);
    return realisticStock;
  }, [
    shipped_eta,
    quantityfbaAvailable,
    quantityfbaReserved,
    quantityfbaInReceiving,
    quantity3pl,
    shipped_qty,
  ]);

  const calcDaysOfSupply = useCallback(() => {
    let daysOfSupply = 0;
    let velocity = 0;
    const realisticStock = calcRealisticStock(
      shipped_eta,
      quantityfbaAvailable,
      quantityfbaReserved,
      quantityfbaInReceiving,
      quantity3pl,
      shipped_qty
    );

    velocity = calcVelocity(salesData);

    daysOfSupply =
      velocity !== 0 && realisticStock !== 0
        ? Math.round(realisticStock / velocity)
        : realisticStock === 0
        ? 0
        : Infinity;
    return daysOfSupply;
  }, [
    shipped_eta,
    quantityfbaAvailable,
    quantityfbaReserved,
    quantityfbaInReceiving,
    quantity3pl,
    shipped_qty,
    calcVelocity,
    salesData,
    calcRealisticStock,
  ]);

  const [open, setOpen] = useState(openAllRows);
  const [isShown, setIsShown] = useState(true);
  const [isFilterActive, setIsFilterActive] = useState(false);

  const toggleOpenStates = (newState) => {
    setOpen(newState);
  };

  let allCalculatedValues = useMemo(() => {
    return {
      "Days of supply": calcDaysOfSupply(salesData),
      "Unit economics": 0,
      "Realistic stock": calcRealisticStock(),
      "Product velocity": calcVelocity(salesData),
      "Velocity change": parseFloat(calcVelocityChange(salesData)),
    };
  }, [
    salesData,
    calcDaysOfSupply,
    calcRealisticStock,
    calcVelocity,
    calcVelocityChange,
  ]);

  const runFilters = useCallback(() => {
    if (filters.length === 0) {
      return true;
    }

    for (const filter of filters) {
      switch (filter.action) {
        case "greater than":
          if (allCalculatedValues[filter.attr] <= filter.value) {
            return false;
          }
          break;
        case "less than":
          if (allCalculatedValues[filter.attr] >= filter.value) {
            return false;
          }
          break;
        case "equal to":
          if (allCalculatedValues[filter.attr] !== filter.value) {
            return false;
          }
          break;
        default:
          break;
      }
    }
    return true;
  }, [filters, allCalculatedValues]);

  useEffect(() => {
    const isParentWithChildren = leaf !== "yes" && !!product.children;
    if (isParentWithChildren) {
      setIsShown(false);
      let anyFilterActive = filters.length > 0;
      setIsFilterActive(anyFilterActive);
    } else {
      let filtersResults = runFilters();
      setIsShown(filtersResults);
      if (leaf === "yes" && filtersResults === true) {
        setTimeout(() => {
          setParentShow(true);
        }, 0);
      }
    }
    // eslint-disable-next-line
  }, [filters]);

  useEffect(() => {
    setOpen(openAllRows);
  }, [openAllRows]);

  const showSalesDataTable =
    leaf !== "yes"
      ? salesData &&
        Object.values(salesData).some((entry) => entry["units"].length > 0)
      : salesData && salesData["units"].length > 0;

  const unitEconomicsCalculationCompleted = (result) => {
    allCalculatedValues["Unit economics"] = result;
  };
  return (
    // render
    <>
      <div style={{ display: isShown ? "block" : "none" }}>
        <TableRow
          style={{
            padding: 0,
            borderWidth: 1,
            backgroundColor:
              leaf !== "yes" && open && product.children
                ? "#E6E9F0"
                : "#ffffff",
          }}
        >
          {leaf !== "yes" ? (
            product.children ? ( // parent with children
              <TableCell sx={{ width: 20 }}>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => toggleOpenStates(!open)}
                >
                  {open ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <KeyboardArrowRightIcon />
                  )}
                </IconButton>
              </TableCell>
            ) : (
              <TableCell sx={{ width: 10 }} /> // parent no children
            )
          ) : (
            <TableCell sx={{ width: 100, backgroundColor: "#E6E9F0" }} /> // child
          )}
          <TableCell>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: !product.children
                  ? leaf === "yes"
                    ? "410px" //child
                    : "480px" //parent no children
                  : "445px", //parent with children
                overflow: "hidden",
              }}
            >
              <img
                src={
                  product.image ||
                  "https://media.istockphoto.com/id/1147544807/vector/thumbnail-image-vector-graphic.jpg?s=612x612&w=0&k=20&c=rnCKVbdxqkjlcs3xH87-9gocETqpspHFXu5dIGB4wuM="
                }
                alt={sku}
                style={{
                  width: 50,
                  height: 50,
                  borderRadius: 5,
                }}
                data-testid="product_image"
              />
              <div
                style={{
                  marginLeft: 10,
                  whiteSpace: "nowrap",
                  marginBottom: -5,
                }}
              >
                <p data-testid="product_name">
                  <Link
                    data-testid="product_href"
                    href={
                      leaf === "yes"
                        ? `https://www.amazon.com/dp/${product.child_asin}?th=1`
                        : `https://www.amazon.com/dp/${product.parent_asin}?th=1`
                    }
                    sx={{
                      fontFamily: "Inter",
                      fontWeight: 600,
                      fontSize: 14,
                      textDecoration: "none",
                    }}
                    target="_blank"
                  >
                    {product.product_name?.substring(0, charMax)}
                  </Link>
                </p>
                <p
                  style={{
                    fontFamily: "Inter",
                    fontWeight: 400,
                    color: "#64748B",
                    fontSize: 12,
                    display:
                      leaf === "yes" || !product.children ? "flex" : "none",
                    alignItems: "center",
                    marginTop:
                      leaf === "yes" || !product.children ? -10 : "auto",
                    marginBottom: leaf === "yes" ? "auto" : 10,
                  }}
                  data-testid="product_sku"
                >
                  <Link
                    data-testid="product_href"
                    href={`/advanced-business-report?level=sku&store=${store}&value=${product.sku}&start_date=${compareConditions.current.start_date}&end_date=${compareConditions.current.end_date}&periodicity=day&metrics=units,avg_price`}
                    sx={{
                      fontFamily: "Inter",
                      fontWeight: 600,
                      fontSize: 14,
                      textDecoration: "none",
                      marginRight: 1,
                    }}
                    target="_blank"
                  >
                    {product.children ? "" : "SKU:"}
                  </Link>
                  {product.children ? "" : " " + sku}
                </p>
                <p
                  style={{
                    fontFamily: "Inter",
                    fontWeight: 400,
                    color: "#64748B",
                    fontSize: 12,
                    display: "flex",
                    alignItems: "center",
                    marginTop: leaf !== "yes" ? -8 : 0,
                  }}
                  data-testid="product_asin"
                >
                  <Link
                    data-testid="product_href"
                    href={`/advanced-business-report?level=${
                      leaf === "yes" ? "child" : "parent"
                    }_asin&store=${store}&value=${
                      leaf === "yes" ? product.child_asin : product.parent_asin
                    }&start_date=${
                      compareConditions.current.start_date
                    }&end_date=${
                      compareConditions.current.end_date
                    }&periodicity=day&metrics=units,avg_price`}
                    sx={{
                      fontFamily: "Inter",
                      fontWeight: 600,
                      fontSize: 14,
                      textDecoration: "none",
                      marginRight: 1,
                    }}
                    target="_blank"
                  >
                    {leaf === "yes" ? "ASIN: " : "PARENT: "}
                  </Link>
                  {leaf === "yes"
                    ? " " + product.child_asin?.substring(0, charMax)
                    : " " + product.parent_asin?.substring(0, charMax)}
                  <div style={{ marginLeft: 8, marginTop: 3 }}>
                    <Link
                      data-testid="product_slack_href"
                      href={`slack://channel?team=T03E7NZR801&id=${slackChannelPerBrand(
                        store
                      )}`}
                      onClick={() => {
                        navigator.clipboard.writeText(
                          "SKU: " +
                            sku +
                            (leaf === "yes"
                              ? " Child: " + product.child_asin
                              : " Parent: " + product.parent_asin)
                        );
                      }}
                      sx={{
                        fontFamily: "Inter",
                        fontWeight: 600,
                        fontSize: 14,
                        textDecoration: "none",
                      }}
                      target="_blank"
                    >
                      <SiSlack
                        style={{
                          width: "15px",
                          height: "15px",
                          color: "#4A154B",
                        }}
                      />
                    </Link>
                  </div>
                </p>
              </div>
            </div>
            {!isFilterActive && (
              <div>
                <div>Inventory:</div>
                <div
                  style={{
                    display: "flex",
                    marginTop: "10px",
                    marginLeft: "5px",
                  }}
                >
                  <div>
                    {"Available: "}
                    <b>{quantityfbaAvailable}</b>
                    <br />
                    {"Reserved: "}
                    <b>{quantityfbaReserved}</b>
                    <br />
                    {"In-Receiving: "}
                    <b>{quantityfbaInReceiving}</b>
                    <br />
                    {"In-Work+Ship: "}
                    <b>{quantityfbaInWorkAndShip}</b>
                    <br />
                  </div>
                  <div style={{ marginLeft: "30px" }}>
                    {"3PL: "}
                    <b>
                      {quantity3pl === undefined || quantity3pl === "" ? (
                        <>N/A</>
                      ) : (
                        <>{quantity3pl}</>
                      )}
                    </b>
                  </div>
                  <div style={{ marginLeft: "30px" }}>
                    {"On the way: "}
                    <b>{shipped_qty}</b>
                    <br />
                    {shipped_eta ? (
                      <>
                        ETA:
                        <b>
                          {shipped_eta.length > 1
                            ? "Many"
                            : shipped_eta.length === 1
                            ? shipped_eta[0]
                            : ""}
                        </b>
                        <br />
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            )}
          </TableCell>
          <div style={{ visibility: isFilterActive ? "hidden" : "visible" }}>
            <TableCell
              sx={{
                fontFamily: "Inter",
                fontWeight: 300,
                color: "#475569",
                fontSize: 12,
                minWidth: 178,
                textAlign: "left",
              }}
            >
              <div>
                {!product.children ? "" : "Avg "}Days of Supply
                <Tooltip
                  title={
                    <>
                      {!product.children
                        ? ""
                        : "Average of all child variations - "}
                      Realistic stock / Velocity
                      <br />
                      *Realistic Stock = Available + Reserved + In-Receiving +
                      3PL + On the way if ETA is within 30 days
                      <br />
                      *Velocity = avg units sold per day in last
                      {velocityDays}
                      days
                    </>
                  }
                >
                  <InfoIcon
                    sx={{
                      marginLeft: "3px",
                      width: "14px",
                      height: "14px",
                      color: "#CBD5E1",
                      marginRight: "20px",
                    }}
                  />
                </Tooltip>
              </div>
              <div
                style={{
                  fontFamily: "Inter",
                  fontWeight: 600,
                  color: "black",
                  fontSize: 24,
                  textAlign: "left",
                  marginBottom: "42px",
                }}
              >
                {calcDaysOfSupply(salesData)}
              </div>
              <div>
                {!product.children ? "" : "Total "} Velocity
                <Tooltip
                  title={
                    <>
                      *Velocity = avg units sold per day in last {velocityDays}
                      days (change in % from previous week)
                    </>
                  }
                >
                  <InfoIcon
                    sx={{
                      marginLeft: "3px",
                      width: "14px",
                      height: "14px",
                      color: "#CBD5E1",
                      marginRight: "20px",
                    }}
                  />
                </Tooltip>
              </div>
              <div
                style={{
                  fontFamily: "Inter",
                  fontWeight: 600,
                  color: "black",
                  fontSize: 18,
                  textAlign: "left",
                  marginBottom: "0px",
                }}
              >
                {showVelocityChange(salesData)}
              </div>
            </TableCell>
            <TableCell
              sx={{
                fontFamily: "Inter",
                fontWeight: 300,
                color: "#475569",
                fontSize: 12,
                minWidth: 190,
                textAlign: "left",
              }}
            >
              <div>
                {!product.children ? "" : "Avg "}Unit Economics
                <Tooltip
                  title={
                    <>
                      {!product.children
                        ? ""
                        : "Average of all child variations - "}
                      Numbers refer to latest date in the selected period
                      <br />
                      PPC per unit is - (total PPC / total Units sold) in the
                      last 7 days
                      <br />
                    </>
                  }
                >
                  <InfoIcon
                    sx={{
                      marginLeft: "3px",
                      width: "14px",
                      height: "14px",
                      color: "#CBD5E1",
                      marginRight: "20px",
                    }}
                  />
                </Tooltip>
                <div>
                  <UnitEconomics
                    unitEconomicsData={unitEconomicsData}
                    onCalculationComplete={unitEconomicsCalculationCompleted}
                    ppcPerUnit={calcPpcPerUnit(
                      leaf === "yes" ? [salesData] : Object.values(salesData)
                    )}
                  />
                </div>
              </div>
            </TableCell>
            <TableCell
              sx={{
                fontFamily: "Inter",
                fontWeight: 300,
                color: "#475569",
                fontSize: 14,
                paddingLeft: 0,
              }}
            >
              {showSalesDataTable ? (
                <DataTable
                  disableColumnReorder={true}
                  rowReordering={false}
                  apiRef={apiRef}
                  rowHeight={35}
                  columnHeaderHeight={35}
                  style={{
                    backgroundColor: "white",
                    height: "200px",
                    width: "45vw",
                  }}
                  columns={tableColumnsForProduct(
                    leaf === "yes"
                      ? salesData
                      : Object.values(salesData).filter(
                          (x) => x.dates.length > 0
                        )[0],
                    leaf
                  )}
                  rows={getTableDailyData(
                    salesData,
                    profitabilityData,
                    ranksData,
                    leaf
                  )}
                  getRowId={(row) => row.id}
                  initialState={{ pinnedColumns: { left: ["field"] } }}
                  hideFooterRowCount
                />
              ) : null}
            </TableCell>
          </div>
        </TableRow>
        {leaf !== "yes" && product.children ? (
          <Collapse
            in={true}
            timeout="auto"
            style={{ display: open ? "block" : "none" }}
          >
            {product.children.map((child) => (
              <ProductRowDetailed
                store={store}
                sku={inventoryData
                  .filter((x) => x.asin === child.child_asin)
                  .map((x) => x.sku)
                  .join(", ")}
                product={child}
                compareConditions={compareConditions}
                leaf={"yes"}
                inventoryData={inventoryData.filter(
                  (x) => child.child_asin === x.asin
                )}
                salesData={salesData[child.child_asin]}
                unitEconomicsData={unitEconomicsData[child.child_asin]}
                filters={filters}
                setParentShow={setIsShown}
                profitabilityData={profitabilityData[child.child_asin]}
                rankData={ranksData[child.child_asin]}
              />
            ))}
          </Collapse>
        ) : null}
        <TableRow>
          <TableCell style={{ padding: 0, borderBottom: 0 }} colSpan={12} />
        </TableRow>
      </div>
    </>
  );
};

export { ProductRowDetailed };
